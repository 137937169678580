jQuery(document).ready(function($) {

  $('.logos-slider').each(function(){
    $(this).slick({
              speed: 5000,
              autoplay: true,
              infinite: true,
              autoplaySpeed: 0,
              cssEase: 'linear',
              slidesToShow: 6,
              slidesToScroll: 1,
              pauseOnHover: true,
              arrows: false,
              responsive: [
                {
                  breakpoint: 750,
                  settings: {
                    slidesToShow: 3,
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 2,
                  }
                }
                ]
    });
  });

});

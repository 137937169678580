jQuery(document).ready(function( $ ) {

  $('.number-slider').addClass('number-slider-animate');

  function ThousandSeparate(val) {
    while (/(\d+)(\d{3})/.test(val.toString())){
      val = val.toString().replace(/(\d+)(\d{3})/, '$1'+','+'$2');
    }
    return val;
}

  $(function() {
  $("#rangeValue").text(ThousandSeparate($('#reversedRange').val()));

  $("#reversedRange").on('change input', function() {
    $("#rangeValue").text(ThousandSeparate($(this).val()));
  });
});

	// $ Works! You can test it with next line if you like
	// console.log($);

  $.fn.wordify = function(){
  this.find(":not(iframe,textarea)").addBack().contents().filter(function() {
    return this.nodeType === 3;
  }).each(function() {
    var textnode = $(this);
    var text = textnode.text();
    text = text.replace(/([^\s-.,;:!?()[\]{}<>"]+)/g,'<span>$1</span>');
    textnode.replaceWith(text);
  });
  return this;
};


  var $hamburger = $(".hamburger");
    $('#off-canvas').on('opened.zf.offCanvas', function(event) {
      //$('.pagination').css('display','block');
    //  console.log("Off-canvas menu was opened.");
      $hamburger.addClass("is-active");
      $('body').css('overflow', 'hidden');
      $('.header.sticky').addClass('offcanvas-active');

    });

  $('#off-canvas').on('closed.zf.offCanvas', function(event) {
    //$('.pagination').css('display','block');
  //  console.log("Off-canvas menu was closed.");
    $hamburger.removeClass("is-active");
    $('body').css('overflow', 'auto');
    $('.header.sticky').removeClass('offcanvas-active');

  });

  // Articles Slider
$('body:not(.non-slider) .articles-slider').slick({
  dots: true,
  arrows: false,
  appendDots: $('.dots-wrap')
});

//Custom Video Player

	$( document ).ready(function() {
		$('#the-video').parent().click(function () {
		  if($(this).children('#the-video').get(0).paused){
			  $(this).children('#the-video').get(0).play();
			  $(this).children('.play-btn').fadeOut();
		    }else{
				$(this).children('#the-video').get(0).pause();
		  	  	$(this).children('.play-btn').fadeIn();
		    }
		});
	});

});

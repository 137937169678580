jQuery(document).ready(function($){
  $('.cards').each(function(){

    $(this).slick({
  centerMode: true,
  centerPadding: '150px',
  slidesToShow: 3,
  arrows: false,
  variableWidth: true,
  swipeToSlide: true,

  speed: 2750,
  autoplay: true,
  infinite: true,
  autoplaySpeed: 0,
  cssEase: 'linear',
  slidesToScroll: .1,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1201,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '60px',
        slidesToShow: 3,
        variableWidth: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '80px',
        slidesToShow: 1,
        variableWidth: false
      }
    }
  ]
});

  });
});

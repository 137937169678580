jQuery(document).ready(function($){



  $('.hero-block').each(function(){

    var thisItem = $(this);

    setTimeout(updateAnimate, 6000);
         function updateAnimate(){
           thisItem.find('.countdown-numbers').hide();
           thisItem.find('.number-slider').hide();

           thisItem.find('.number-text').show();
           thisItem.find('#reversedRange').show();
         }

    $('[data-slider]').on('moved.zf.slider', function() {

    //console.log(Number(thisItem.find('input[type="number"]').val()).toLocaleString());

    var localeNumber = Number(thisItem.find('input[type="number"]').val()).toLocaleString();

    thisItem.find('.number-text').text(localeNumber);


    });

    var blockID = $(this).attr('id');

    console.log(blockID);

    var counter = 'counter-'+blockID;

    var counterNumbers = $('#'+counter).attr('data-counter');

    var totalCounter = 'totalCounter-'+blockID;

    var totalCounterNumbers = $('#'+totalCounter).attr('data-counter');

    var initialCounterNumbers = $('#'+counter).attr('data-initial-counter');

    console.log('final: '+initialCounterNumbers);

    var numAnimCounter = new countUp.CountUp(counter, initialCounterNumbers, {
      duration: 8,
      startVal: counterNumbers
    });
    numAnimCounter.start();

    var numAnimTotalCounter = new countUp.CountUp(totalCounter, totalCounterNumbers, {duration: 8});
    numAnimTotalCounter.start();

    var dateSlider = $(this).find('.dates-slider');

var item_length = dateSlider.find('> div').length - 1;

var slider = dateSlider.slick({
      vertical: true,
      autoplay: true,
      arrows: false,
      autoplaySpeed: 500,
      infinite: false,
      autoplaySpeed: 1200
    });

    // Get the current slide
    var currentSlide = dateSlider.slick('slickCurrentSlide');


    dateSlider.on('afterChange', function(event, slick, currentSlide, nextSlide){

  //check the length of total items in .slide container
  //if that number is the same with the number of the last slide
  //Then pause the slider
  if( item_length == currentSlide ){
      //this should do the same thing -> slider.slickPause();
      dateSlider.slick('slickPause');
  };

});





  });

});

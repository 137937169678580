jQuery(document).ready(function($){
  $('.spark-slider').each(function(){
    $(this).slick({

autoplaySpeed: 1000,
centerMode: true,
  centerPadding: '400px',
  slidesToShow: 1,
  dots: true,
  arrows: false,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          centerPadding: '200px'
        }
      },
      {
        breakpoint: 900,
        settings: {
          centerPadding: '10px'
        }
      }
    ]
});
  });
});

jQuery(document).ready(function($){
  $('.list-slider').each(function(){
    $(this).slick({

vertical: true,
autoplay: true,
autoplaySpeed: 1000,
centerMode: true,
  centerPadding: '150px',
  slidesToShow: 3,
  arrows: false,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        centerPadding: '0px 0 180px'
      }
    }
  ]

});
  });
});
